import { APITypesV1 } from "@cur8/api-client";
import { fromAPI, ImmutableScan } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

export function useLatestLLMScan(patientId: string | undefined) {
  const api = useAPIClient();

  const [latestLLMScan, setLatestLLMScan] = useState<ImmutableScan>();

  const reloadLatestLLMScan = useCallback(() => {
    const request = patientId
      ? api.scan.queryImmutableScans({
          patientId,
          scanType: "llm/1",
          order: APITypesV1.SortOrder.Desc,
          pageSize: 1,
        })
      : undefined;

    request?.result
      .then((result) => result.items.map(fromAPI.toImmutableScan))
      .then((scans) => {
        if (scans.length > 0) setLatestLLMScan(scans[0]);
      })
      .catch(silenceAbort);

    return () => {
      console.log("cleaning up latestLLMScan");
      request?.abandon();
      setLatestLLMScan(undefined);
    };
  }, [api, patientId]);

  useEffect(() => {
    reloadLatestLLMScan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { latestLLMScan, reloadLatestLLMScan };
}
