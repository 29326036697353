import { APITypesV1 } from "@cur8/api-client";
import { Patient } from "@cur8/rich-entity";
import { DateTime } from "luxon";
import { useCallback, useRef } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useAppInsights } from "render/context/AppInsightsContext";
import { RecordingSession, uploadPatientAudioBlob } from "../utils";
import { VadData, VoiceActivityDetector } from "../vad";

const MIME_TYPE = "audio/webm;codecs=opus";
const MIN_REC_TIME_S = 1;
const TARGET_REC_TIME_MS = 60000;

type AudioFileRecord = {
  fileName: string;
  durationSeconds: number;
  meanRms: number;
};

export function useAudioRecordingState() {
  const api = useAPIClient();
  const vadRef = useRef<VoiceActivityDetector>();
  const recStartTimeRef = useRef(DateTime.now().toMillis()); // used to track the length of the current ~1 min recording
  const recTotalTimeRef = useRef(DateTime.now().toMillis()); // used to track the total recording length
  const filesRef = useRef<AudioFileRecord[]>([]);
  const isStoppedRef = useRef(true);
  const timestampRef = useRef<number>();
  const hasErrorRef = useRef(false);
  const meanRmsRef = useRef(-100);
  const scanRef = useRef<APITypesV1.ImmutableScan>();
  const appInsights = useAppInsights();

  const uploadAudioFileJsonIfFinished = useCallback(
    async (patient: Patient, durationSeconds: number) => {
      if (
        patient &&
        scanRef.current &&
        timestampRef.current &&
        isStoppedRef.current &&
        filesRef.current.length > 0
      ) {
        try {
          // mark audio complete
          await api.scan.createImmutableScanResult({
            patientId: patient.patientId,
            scanId: scanRef.current.id,
            scanVersion: scanRef.current.version,
            resultName: "audioFiles",
            state: APITypesV1.ResultState.Complete,
          }).result;

          await api.scan.createImmutableScanResult({
            patientId: patient.patientId,
            scanId: scanRef.current.id,
            scanVersion: scanRef.current.version,
            resultName: "audioRecordingCount",
            state: APITypesV1.ResultState.Complete,
            data: {
              $type: "AudioRecordingCount",
              count: filesRef.current.length.toString(),
              duration: durationSeconds,
            },
          }).result;

          filesRef.current = [];
        } catch {
          appInsights.trackEvent(
            { name: "recording_audiofile_upload_failed" },
            { patient }
          );
        }
      }
    },
    [api, appInsights]
  );

  const stop = useCallback(
    (patient: Patient) => {
      vadRef.current?.stop();
      vadRef.current = undefined;
      isStoppedRef.current = true;
      setTimeout(async () => {
        // safety net to ensure audiofile.json is uploaded. onDataAvailable after last chunk is not always reliable ...

        const durationSeconds =
          DateTime.now().toMillis() - recTotalTimeRef.current / 1000;

        if (filesRef.current.length > 0) {
          console.warn(
            "audiofile.json not uploaded 10s after stop ... uploading now"
          );
          await uploadAudioFileJsonIfFinished(patient, durationSeconds);
          appInsights.trackEvent(
            { name: "recording_uploading_audiofile_late" },
            { patient }
          );
        }
        scanRef.current = undefined;
      }, 10000);
    },
    [appInsights, uploadAudioFileJsonIfFinished]
  );

  const onDataAvailable = useCallback(
    async (patient: Patient, event: BlobEvent) => {
      if (!timestampRef.current) {
        console.warn("no timestampRef...");
        return;
      }
      if (!scanRef.current) {
        console.error("has no scan when trying to upload audio file!");
        return;
      }

      const durationSeconds =
        DateTime.now().toMillis() - recStartTimeRef.current / 1000;

      vadRef.current?.stop();

      if (durationSeconds > MIN_REC_TIME_S) {
        console.debug(
          `recorded ${durationSeconds}s, uploading [${filesRef.current.length}]: ${event.data.size} bytes`
        );

        const fileName = `${filesRef.current.length}.webm`;

        const success = await uploadPatientAudioBlob(
          api,
          patient,
          event.data,
          fileName,
          scanRef.current
        );

        if (success) {
          filesRef.current.push({
            fileName,
            durationSeconds,
            meanRms: meanRmsRef.current,
          });
        } else {
          hasErrorRef.current = true;
          console.error("uploading of file failed");
        }
      } else {
        console.warn("Recorded segment too short, not sending...");
      }

      const totalDuration =
        (DateTime.now().toMillis() - recTotalTimeRef.current) / 1000;

      await uploadAudioFileJsonIfFinished(patient, totalDuration);

      if (!isStoppedRef.current) {
        try {
          vadRef.current?.start();
        } catch {
          console.warn("could not restart vad..");
        }
      }
    },
    [api, uploadAudioFileJsonIfFinished]
  );

  const configureMediaRecorder = useCallback(
    (patient: Patient, stream: MediaStream) => {
      const mediaRecorder = new MediaRecorder(stream, { mimeType: MIME_TYPE });
      mediaRecorder.onstart = () => {
        recStartTimeRef.current = DateTime.now().toMillis();
      };
      mediaRecorder.start();
      mediaRecorder.addEventListener("dataavailable", (event) => {
        onDataAvailable(patient, event);
      });
      return mediaRecorder;
    },
    [onDataAvailable]
  );

  const handleVADUpdate = useCallback(
    (mediaRecorder: MediaRecorder, data: VadData) => {
      meanRmsRef.current = data.meanRms;
      if (!data.active && mediaRecorder.state === "recording") {
        // stopped talking
        const recTime = DateTime.now().toMillis() - recStartTimeRef.current;
        if (recTime > TARGET_REC_TIME_MS) {
          console.debug("stopping media recorder");
          mediaRecorder.stop();
        }
      } else if (
        data.active &&
        mediaRecorder.state !== "recording" &&
        !isStoppedRef.current
      ) {
        // started talking
        console.debug("starting recorder");
        try {
          mediaRecorder.start();
        } catch {
          console.warn("could not restart recorder.");
        }
      } else if (isStoppedRef.current) {
        appInsights.trackEvent({ name: "recording_vad_update_when_stopped" });
        console.warn("vad update when stopped...");
      }
    },
    [appInsights]
  );

  const configureVAD = useCallback(
    async (stream: MediaStream, mediaRecorder: MediaRecorder) => {
      const vad = new VoiceActivityDetector(stream, (data) => {
        // Handle stopping/starting of recorder based on voice activity
        try {
          handleVADUpdate(mediaRecorder, data);
        } catch (err) {
          console.warn("could not manage recorder's state.", err);
        }
      });
      await vad.initialize();
      return vad;
    },
    [handleVADUpdate]
  );

  const start = useCallback(
    async (
      patient: Patient,
      deviceId: string,
      scan: APITypesV1.ImmutableScan
    ) => {
      isStoppedRef.current = false;
      filesRef.current = [];
      hasErrorRef.current = false;
      scanRef.current = scan;
      recTotalTimeRef.current = DateTime.now().toMillis();

      // If no preferred device was found, deviceId is empty. In this case, do not constrain:
      const audio = deviceId ? { deviceId: { exact: deviceId } } : true;

      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio });
        timestampRef.current = DateTime.now().toMillis();
        const mediaRecorder = configureMediaRecorder(patient, stream);
        vadRef.current = await configureVAD(stream, mediaRecorder);
        return new RecordingSession(stream);
      } catch (err) {
        console.error("Could not start recording:", err);
      }
    },
    [configureMediaRecorder, configureVAD]
  );

  return { start, stop };
}
