import { VisitSummary } from "@cur8/rich-entity";
import { toList } from "@pomle/react-flat-store";
import { useNav } from "@pomle/react-router-paths";
import { TransSumPair } from "lib/doctor-scribe/types";
import { generatedSummaryWithGreeting } from "lib/doctor-scribe/utils";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMSAL } from "render/context/MSALContext";
import PatientName from "render/fragments/patient/PatientName";
import DoctorScribe from "render/fragments/visit/DoctorScribe";
import { useLLMConsent } from "render/fragments/visit/DoctorScribe/hooks/useLLMConsent";
import { useLLMScan } from "render/fragments/visit/DoctorScribe/hooks/useLLMScan";
import { dropGreeting } from "render/fragments/visit/DoctorScribe/postProcessing";
import PreviousVisitSummary from "render/fragments/visit/PreviousVisitSummary";
import { getPreviousSummaryFor } from "render/fragments/visit/PreviousVisitSummary/summary";
import VisitForm from "render/fragments/visit/VisitForm";
import { usePatient } from "render/hooks/api/usePatient";
import { useVisitStore } from "render/hooks/api/useVisitStore";
import { useFormHandle } from "render/hooks/useFormHandle";
import { paths } from "render/routes/paths";
import Form from "render/ui/form/Form";
import FramedPage from "render/ui/layouts/FramedPage/FramedPage";
import PageHeader from "render/ui/layouts/PageHeader";
import BackButtonLink from "render/ui/trigger/BackButtonLink";
import ButtonSet from "render/ui/trigger/ButtonSet";
import SubmitButton from "render/ui/trigger/SubmitButton";
import { usePatientVisitSummaries } from "../PatientDetailPage/hooks/usePatientVisitSummaries";
import { useLatestScans } from "./hooks/useLatestScans";
import styles from "./styles.module.sass";

const INITIAL: VisitSummary = {
  id: "",
  patientId: "",
  summaryText: "",
  visitDate: DateTime.invalid("Unknown"),
  audit: {},
};

interface PatientVisitSummaryCreatePageProps {
  patientId: string;
}

export default function PatientVisitSummaryCreatePage({
  patientId,
}: PatientVisitSummaryCreatePageProps) {
  const { createVisit } = useVisitStore();
  const { entries: visitSummaries } = usePatientVisitSummaries(patientId);

  const { latestScans } = useLatestScans(patientId);

  const llmScan = useLLMScan(patientId);
  const { saveReviewResult, latestLLMScan, reloadLatestLLMScan } = llmScan;
  const { auth } = useMSAL();

  const autoVisitDate = useMemo(() => {
    if (latestScans?.data) {
      if (latestScans.data.length > 0) {
        return latestScans.data[0].versionCreatedAt;
      }

      return DateTime.now();
    }
  }, [latestScans]);

  const isFormDisabled = useMemo(() => {
    return autoVisitDate == null;
  }, [autoVisitDate]);

  const patient = usePatient(patientId);

  const [visit, setVisit] = useState(INITIAL);
  const [summary, setSummary] = useState<TransSumPair>();
  const { canUseSummaries } = useLLMConsent(patientId);

  const previousSummary = useMemo(() => {
    if (visitSummaries == null) {
      return {
        loaded: false,
        data: undefined,
      };
    }

    return {
      loaded: true,
      data: getPreviousSummaryFor(toList(visitSummaries)),
    };
  }, [visitSummaries]);

  useEffect(() => {
    if (autoVisitDate) {
      setVisit((prev) => {
        return {
          ...prev,
          followUpDate: autoVisitDate.plus({ months: 6 }),
          visitDate: autoVisitDate,
        };
      });
    }
  }, [autoVisitDate]);

  const handleCreate = useCallback(async () => {
    if (summary?.generatedSummary) {
      // If there is a summary, save for analysis and fine tuning of summary model
      if (latestLLMScan) {
        const readTime = Math.round(
          (new Date().getTime() - summary.readTime) / 1000
        );
        const savedSummary = dropGreeting(visit.summaryText);
        await saveReviewResult(latestLLMScan, {
          $type: "InternalReviewResult",
          savedSummary,
          readTime,
          diffCount: savedSummary.length - summary.generatedSummary.length,
          isLiked: summary.isLiked !== undefined ? summary.isLiked : null,
          user: auth?.account?.username || "",
        });
        reloadLatestLLMScan();
      }
    }

    return createVisit(patientId, visit).then(() => {
      if (autoVisitDate) {
        setVisit({
          ...INITIAL,
          followUpDate: autoVisitDate.plus({ months: 6 }),
          visitDate: autoVisitDate,
        });
      } else {
        setVisit(INITIAL);
      }
    });
  }, [
    createVisit,
    patientId,
    visit,
    summary,
    autoVisitDate,
    latestLLMScan,
    saveReviewResult,
    reloadLatestLLMScan,
    auth,
  ]);

  const submitHandle = useFormHandle(handleCreate);

  const nav = {
    patient: useNav(paths.patient.detail),
  };

  const onSummary = useCallback(
    (newSummary: TransSumPair) => {
      setSummary(newSummary);
      setVisit((prevVisit) => ({
        ...prevVisit,
        summaryText: generatedSummaryWithGreeting(newSummary, patient),
      }));
    },
    [setVisit, patient]
  );

  const scribeComponent = (
    <DoctorScribe
      patientId={patientId}
      llmScan={llmScan}
      onSummary={onSummary}
      onLike={(isLiked) =>
        setSummary((prev) => (prev ? { ...prev, isLiked } : undefined))
      }
    />
  );

  const noConsentComponent = (
    <div className={styles.noconsent}>
      Cannot use automatic summaries and fact checker since the patient has not
      given consent.
    </div>
  );

  return (
    <FramedPage>
      <div className={styles.PatientVisitSummaryCreatePage}>
        <BackButtonLink to={nav.patient.to({ patientId })}>
          {patient ? <PatientName patient={patient} /> : "Back"}
        </BackButtonLink>

        <div>
          <PageHeader caption="Visit Summary" />
          <div className={styles.date}>
            {visit.visitDate.isValid
              ? visit.visitDate.toFormat("LLL dd yyyy")
              : "-"}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.item}>
            {canUseSummaries ? scribeComponent : noConsentComponent}

            <Form handle={submitHandle}>
              <VisitForm
                disabled={isFormDisabled}
                visit={visit}
                onChange={setVisit}
              />
              <ButtonSet>
                <SubmitButton disabled={isFormDisabled} handle={submitHandle}>
                  Save Summary
                </SubmitButton>
              </ButtonSet>
            </Form>
          </div>
          <div className={styles.item}>
            {previousSummary.loaded && (
              <PreviousVisitSummary visit={previousSummary.data} />
            )}
          </div>
        </div>
      </div>
    </FramedPage>
  );
}
